@import '../../App.css';

.smallie {
  text-align: center;
}

.owl-carousel .owl-item img {
  /* width: 60% !important; */
  margin-left: auto !important;
  margin-right: auto !important;
  position: relative;
  top: 35px;
}

.card {
  width: 90%;
  height: 530px;
  margin-bottom: 50px;
  box-shadow: var(--bs);
  transition: 0.6s;
}

.card:hover {
  transform: scale(1.05);
}

.card:hover .card-link {
  display: inline-block;
}

.card-content {
  color: black;
  margin-top: 0;
  padding-top: 7px !important;
}
.card-title {
  margin-top: 20px;
  font-weight: bold;
}

.card-para {
  font-size: var(--fs-body);
  margin-top: 10px;
}

.card-link {
  margin-top: 20px;
  display: none;
  padding: 0.3em 2.5em;
  color: var(--clr-accent);
  cursor: pointer;
  font-size: 1rem;
  letter-spacing: 2px;
  font-weight: var(--fw-bold);
}
/* .card-link:after {
  content: ' ';
  position: absolute;
  display: block;
  width: 100px;
  margin-top: 0px;
  border: 1.5px solid var(--clr-accent);
  border-radius: 4px;
  transition: all 0.3s ease-in-out;
  transform: translate(0) translateZ(0);
  -webkit-transform: translate(0, 0) translateZ(0);
  left: 37%;
} */

.card-link:hover {
  color: var(--clr-accent);
}

.carrd {
  border-radius: 100px 0 100px 0;
  height: 500px;
  background-color: var(--clr-gray);
  padding-left: 6px;
}
.service-card {
  box-shadow: var(--bs);
}

img.testi-img {
  border-radius: 20%;
  margin: 0 auto;
  height: 160px !important;
  width: 160px !important;
}

.img-txt {
  text-transform: uppercase;
  margin: 20px 0 -15px;
  color: var(--clr-accent);
}

.status {
  font-weight: bold;
}

.img-area {
  margin: 0 auto !important;
  height: 200px !important;
  width: 200px !important;
  overflow: hidden;
  display: block;
  border-radius: 50%;
}

.img-area img {
  width: 100% !important;
}
.our-services {
  background-color: var(--clr-gray);
}

.card-para i {
  color: var(--clr-accent);
}

.testi-hr {
  background-color: var(--clr-accent);
  margin: 8px auto;
  width: 70%;
}

.section-heading {
  font-weight: bolder;
  margin-bottom: 40px;
  margin-top: -20px;
}

@media (min-width: 320px) and (max-width: 480px) {
  .card {
    width: 100%;
    height: 400px;
    margin-bottom: 5px;
  }
  .card-image {
    margin-bottom: 8px !important;
  }

  .carrd {
    height: 500px;
  }
  .card-content {
    margin-top: 0;
    padding-top: 0.5em !important;
  }
  .card-title {
    font-size: 30px;
    margin-top: 10px;
  }

  .card-para {
    font-size: 10px;
  }
  /* .card-link:after {
    left: 32%;
  } */

  /* .carrd {
    margin-left: 7px;
  } */

  .img-txt {
    font-size: 15px;
  }

  .testi-para {
    font-size: 15px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  /* .card-link:after {
    left: 34%;
  } */
}
