@import '../../App.css';

.contact-1 {
  margin-left: 80px;
  margin-top: 60px;
}

.con-tact {
  margin-top: 30px;
}

.con-tact > h4 {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
}

.con-tact a {
  text-decoration: none;
  color: var(--clr-dark);
}

.con-tact a:hover {
  color: var(--clr-accent);
}

.contain {
  background-color: rgb(247, 247, 247);
  padding: 20px;
}

.project-form {
  padding: 30px;
}

form .control i {
  visibility: hidden;
  position: absolute;
  top: 15px;
  right: 10px;
}
.control small {
  color: #e74c3c;
  font-weight: bold;
}

.field.success input {
  border: 2px solid #2ecc71;
}

.field.error input {
  border: 2px solid #e74c3c;
}

.field.success i.fa-check-circle {
  color: #2ecc71;
  visibility: visible;
}

.field.error i.fa-exclamation-circle {
  color: #e74c3c;
  visibility: visible;
}

.field.error small {
  color: #e74c3c;
  visibility: visible;
}

@media (min-width: 320px) and (max-width: 480px) {
  .contact-1 {
    margin-left: 40px;
    margin-top: 60px;
  }
}
