@import '../../App.css';

.about-container {
  width: 1140px;
  margin: 50px auto;
}
.about-title {
  font-family: var(--ff-secondary) !important;
  font-weight: bolder;
}

.about-img {
  margin: 0 auto;
}
.about-col {
  padding: 100px;
}

.about-h3 {
  font-weight: bold;
}
.about-btn {
  display: inline-block;
  padding: 0.5em 2.5em;
  background: var(--clr-accent);
  color: var(--clr-light);
  text-decoration: none;
  cursor: pointer;
  text-align: center;
  font-size: 0.8rem;
  width: 190px;
  /* text-transform: uppercase; */
  letter-spacing: 2px;
  border-radius: 20px;
  font-weight: var(--fw-bold);
  margin-top: 10px;
  outline: none;
  border: none;
  /* transition: transform 200ms ease-in-out; */
}

.about-btn:hover {
  background-color: var(--clr-light);
  border: 2px solid var(--clr-accent);
  color: var(--clr-accent);
}

.graay {
  background: var(--clr-gray);
}

@media (min-width: 768px) and (max-width: 1024px) {
  .about-container {
    width: 640px;
    margin: 20px auto;
  }
  .about-img {
    margin: 0;
  }
  .about-col {
    padding: 30px;
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  section {
    padding: 3rem 0;
  }
  .about-container {
    width: 250px;
    margin: 10px auto;
  }

  .about-title {
    font-size: 20px;
  }
  .about-img {
    margin-left: 0;
  }
  .about-col {
    padding: 20px;
  }
}

/* Count Section Styles */
.cover {
  width: 100%;
  height: 55vmin;
  background: url('/images/fav-two.jpg');
  background-size: 150%;
  position: relative;
}

.cover .content {
  background: rgba(0, 0, 0, 0.7);
  height: 100%;
  width: 100%;
  padding-top: 19vmin;
}

.cover .content > h1 {
  font-family: var(--ff-secondary);
  font-size: 6vmin;
  color: whitesmoke;
}

.cover .content > p {
  font-family: var(--ff-secondary);
  font-size: 2vmin;
  color: #e5e5e5;
}

.numbers .rect {
  position: relative;
  z-index: 1;
  background: white;
  width: 17rem;
  height: 12rem;
  padding-top: 3.5vmin;
  margin: 1rem;
  border-radius: 0.5em;
  box-shadow: 1px 2px 50px 0px rgba(255, 0, 0, 0.349);
}
.numbers {
  margin-top: -15vmin;
  text-align: center;
  justify-content: center;
}

.numbers .rect h1 {
  font-size: 5rem;
  color: tomato;
}

.numbers .rect p {
  font-family: var(--ff-secondary);
  font-weight: bold;
}

@media only screen and (max-width: 1024px) {
  .section-2 .cover {
    background-position: -25vmin -10vmin;
  }

  .numbers .rect {
    width: 10rem;
    height: 8rem;
  }

  .numbers .rect h1 {
    font-size: 2rem;
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  .numbers .rect {
    height: 5rem;
    margin: 0.5rem;
  }
  .numbers .rect h1 {
    font-size: 1rem;
    margin-bottom: 10px;
  }
}
