@import '../../App.css';

/* footer */

/* footer {
  background: #fff;
  overflow-x: hidden;
  padding: 14vmin 18vmin;
} */

.footer {
  padding: 3rem 0 1rem;
}

footer p > span {
  color: var(--clr-accent);
}

footer input {
  border: none !important ;
}

.footer-main {
  background-color: var(--clr-gray);
  padding: 3rem 0 1rem;
}

/* footer input::placeholder {
  color: white !important;
}

footer .input-group .input-group-text {
  background: var(--clr-accent);
  border: none;
} */

footer .column i {
  color: var(--clr-dark);
}

footer .column i {
  padding: 0 0.5em;
}

.footer-icons {
  margin-top: 20px;
  align-items: flex-end;
  cursor: pointer;
}

.gray {
  background-color: rgb(241, 239, 239);
  width: 100%;
}
.first-col {
  margin-left: 80px;
}
.second-col {
  margin-left: 100px;
}
.third-col {
  margin-left: 100px;
}

.sec-link {
  display: block;
  text-align: left;
  color: var(--clr-dark);
}

.sec-link:hover {
  color: var(--clr-accent);
}
#info-accent {
  color: var(--clr-accent);
}
.footer-info {
  color: var(--clr-dark);
}
.mute-p {
  color: gray;
}
.foot-text {
  color: rgb(35, 35, 36);
}

.footer-btn {
  display: inline-block;
  padding: 0.5em 2.5em;
  background: var(--clr-accent);
  color: var(--clr-light);
  text-decoration: none;
  cursor: pointer;
  text-align: center;
  font-size: 0.8rem;
  width: 190px;
  /* text-transform: uppercase; */
  letter-spacing: 2px;
  border-radius: 20px;
  font-weight: var(--fw-bold);
  /* transition: transform 200ms ease-in-out; */
}

.footer-btn:hover {
  background-color: var(--clr-light);
  border: 2px solid var(--clr-accent);
  color: var(--clr-accent);
}

.footer-btn1 {
  display: inline-block;
  padding: 0.5em 2.5em;
  background: var(--clr-light);
  color: var(--clr-accent);
  border: 2px solid var(--clr-accent);
  text-decoration: none;
  cursor: pointer;
  text-align: center;
  font-size: 0.8rem;
  width: 190px;
  /* text-transform: uppercase; */
  letter-spacing: 2px;
  border-radius: 20px;
  font-weight: var(--fw-bold);
  /* transition: transform 200ms ease-in-out; */
}

.footer-btn1:hover {
  background-color: var(--clr-accent);
  border: 2px solid var(--clr-light);
  color: var(--clr-light);
}

@media (min-width: 320px) and (max-width: 480px) {
  .footer-icons {
    margin-top: 0;
    padding-top: 0 !important;
    margin-left: 30px;
  }
  .first-col {
    margin-left: 0;
  }
  .second-col {
    margin-left: 20px;
  }
  .third-col {
    margin-left: 0;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .footer-icons {
    margin-top: 5px;
    margin-left: 30px;
  }
  .first-col {
    margin-left: 60px;
  }
  .second-col {
    margin-left: 80px;
  }
  .third-col {
    margin-left: 55px;
    margin-bottom: 20px;
  }
}

@media (min-width: 1025px) and (max-width: 1439px) {
  .footer-icons {
    margin-top: 50px;
  }
}
