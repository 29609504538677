@import './components/css/affirm.css';
@import './components/css/footer.css';
@import './components/css/services.css';
@import './components/css/technology.css';
@import './components/css/testimonial.css';

/* Pages import */
@import './pages/css/blog.css';
@import './pages/css/about.css';
@import './pages/css/enquiry.css';
@import './pages/css/services.css';
@import './pages/css/deliverypolicy.css';
/* Custom Properties, update these for your own design */
:root {
  --ff-primary: 'Lora', serif;
  --ff-secondary: 'Roboto Slab', serif;

  --fw-reg: 400;
  --fw-bold: 700;

  --clr-light: #fff;
  --clr-dark: #303030;
  --clr-accent: #f13c31;
  --clr-gray: #fafafa;
  --clr-darkgrey: rgb(59, 59, 59);

  --fs-h1: 3rem;
  --fs-h2: 2.25rem;
  --fs-h3: 1.25rem;
  --fs-body: 1rem;

  --bs: 0.25em 0.25em 0.75em rgba(0, 0, 0, 0.25),
    0.125em 0.125em 0.25em rgba(0, 0, 0, 0.15);
}

@media (min-width: 800px) {
  :root {
    --fs-h1: 4.5rem;
    --fs-h2: 3.75rem;
    --fs-h3: 1.5rem;
    --fs-h4: 1rem;
    --fs-body: 1.125rem;
  }
}

@media (min-width: 300px) and (max-width: 500px) {
  :root {
    --fs-h1: 3.5rem;
    --fs-h2: 2.75rem;
    --fs-h3: 1rem;
    --fs-h4: 1rem;
    --fs-body: 0.6rem;
  }
}
/*General styles */

/* endable this to add smooth scrolling */
html {
  scroll-behavior: smooth;
}

body {
  background: var(--clr-dark);
  color: var(--clr-light);
  margin: 0;
  font-family: var(--ff-primary);
  font-size: var(--fs-body);
  line-height: 1.6;
  overflow: hidden;
}

.light-mode {
  background: var(--clr-light);
  color: var(--clr-dark);
}

.light-mode nav .nav-link,
.foot-text,
.post-header a {
  color: var(--clr-dark);
}

.light-mode .testi-para {
  color: var(--clr-dark);
}
.light-mode .sec-link {
  color: var(--clr-dark);
}
.light-mode .status {
  color: var(--clr-dark);
}

.light-mode .content-heading {
  color: var(--clr-dark);
}

.light-mode .affirm-section {
  background-color: var(--clr-gray);
}

.light-mode .footer-main {
  background-color: var(--clr-gray);
}

.light-mode .carrd {
  background-color: var(--clr-gray);
}

.light-mode .graay {
  background-color: var(--clr-gray);
}

.light-mode .our-services {
  background-color: var(--clr-gray);
}

.light-mode .gray {
  background-color: rgb(241, 239, 239);
}
.light-mode .foot-text {
  color: var(--clr-dark);
}

.dark-mode {
  background: var(--clr-dark);
  color: var(--clr-light);
}

.dark-mode nav {
  background: var(--clr-dark);
}

.dark-mode .post-header a {
  color: var(--clr-light);
}

.dark-mode .navbar-end {
  background: var(--clr-dark);
}

.dark-mode .navbar-menu {
  background: var(--clr-dark);
}

.dark-mode nav .nav-link,
.status,
.testi-para,
.sec-link,
.foot-text {
  color: var(--clr-light);
}

.dark-mode .navbar-burger {
  color: var(--clr-light);
}

.dark-mode .content-heading {
  color: var(--clr-light);
}

.dark-mode .enquiry-link {
  color: var(--clr-light);
}

.dark-mode .sec-link {
  color: var(--clr-light);
}
.dark-mode .affirm-section,
.carrd,
.graay,
.our-services,
.footer-main {
  background-color: var(--clr-darkgrey);
}
.dark-mode .gray {
  background-color: rgb(27, 27, 27);
}

.dark-mode .project-form {
  background-color: var(--clr-darkgrey);
}

.dark-mode .project-form input {
  background-color: var(--clr-darkgrey);
  color: var(--clr-light);
}

.dark-mode .project-form input::placeholder {
  color: var(--clr-light);
}

.dark-mode .port-title {
  color: var(--clr-light);
}

.dark-mode .project-form textarea::placeholder {
  color: var(--clr-light);
}

.dark-mode .project-form textarea {
  background-color: var(--clr-darkgrey);
  color: var(--clr-light);
}

.dark-mode .project-form select {
  background-color: var(--clr-darkgrey);
  color: var(--clr-light);
}

.dark-mode .project-form .label {
  color: var(--clr-light);
}

.dark-mode .footer-info {
  color: var(--clr-light);
}

.dark-mode .contain {
  background-color: var(--clr-dark);
}

.dark-mode .footer-icons i {
  color: var(--clr-light);
}

.fa-moon {
  font-size: 23px;
}

.fa-sun {
  font-size: 25px;
  color: var(--clr-light);
}

.toggle {
  cursor: pointer;
}

section {
  padding: 5em 2em;
}

img {
  display: block;
  max-width: 100%;
}

strong {
  font-weight: var(--fw-bold);
}

:focus {
  outline: none;
  /* outline-offset: 3px; */
}

/*  scroll up */

#myBtn {
  display: block;
  position: fixed;
  bottom: 20px;
  right: 30px;
  z-index: 9999;
  border: none;
  outline: none;
  background-color: var(--clr-accent);
  color: white;
  cursor: pointer;
  padding: 15px;
  border-radius: 6px;
  opacity: 0.8;
}

#myBtn:hover {
  background-color: var(--clr-dark);
}

/* Buttons */

.btnn {
  display: inline-block;
  padding: 0.5em 2.5em;
  background: var(--clr-accent);
  color: var(--clr-light);
  text-decoration: none;
  cursor: pointer;
  font-size: 0.8rem;
  /* text-transform: uppercase; */
  letter-spacing: 2px;
  border-radius: 50px;
  font-weight: var(--fw-bold);
  /* transition: transform 200ms ease-in-out; */
}

.btnn:hover {
  background-color: var(--clr-light);
  border: 2px solid var(--clr-accent);
  color: var(--clr-accent);
}

/* .graay {
  background: var(--clr-gray);
} */
.nav-btn {
  display: inline-block;
  padding: 0.5em 2.5em;
  background: var(--clr-accent);
  color: var(--clr-light);
  text-decoration: none;
  cursor: pointer;
  font-size: 0.8rem;
  /* text-transform: uppercase; */
  letter-spacing: 2px;
  border-radius: 50px;
  font-weight: var(--fw-bold);
  /* transition: transform 200ms ease-in-out; */
}

.navbar-burger {
  margin-top: 20px;
  margin-right: 20px;
  font-size: 30px;
}

.nav-btn:hover {
  background-color: var(--clr-light);
  border: 2px solid var(--clr-accent);
  color: var(--clr-accent);
}

/* Typography */

h1,
h2,
h3,
h4 {
  line-height: 1;
  margin: 0;
}

h1 {
  font-size: var(--fs-h1);
}
h2 {
  font-size: var(--fs-h2);
}
h3 {
  font-size: var(--fs-h3);
}

h4 {
  font-size: var(--fs-h4);
}

.section__title {
  margin-bottom: 0.25em;
}

.section__title--intro {
  font-weight: var(--fw-reg);
}

.section__title--intro1 {
  margin-bottom: 0.3em;
  font-weight: var(--fw-reg);
}

.section__title--intro strong {
  display: block;
}

.section__subtitle {
  margin: 0;
  font-size: var(--fs-h3);
}

.section__subtitle--intro,
.section__subtitle--about {
  background: var(--clr-accent);
  padding: 0.25em 1em;
  font-family: var(--ff-secondary);
  margin-bottom: 1em;
}

.section__subtitle--work {
  color: var(--clr-accent);
  font-weight: var(--fw-bold);
  margin-bottom: 2em;
}

.logo {
  font-weight: bold;
  font-size: 40px;
  color: brown;
}

.hey {
  padding: 30px;
}

.hey-container {
  display: flex;
  border-radius: 30px;
}

.hey-child {
  flex: 1;
}

.hey-child:first-child {
  margin-right: 20px;
}

.base {
  background: rgb(229, 77, 60);
  background: linear-gradient(
    0deg,
    rgba(229, 77, 60, 1) 13%,
    rgba(241, 139, 84, 0.938813025210084) 54%
  );
  padding: 20px;
  color: white;
}

.nav-link {
  margin-right: 50px;
  font-weight: var(--fw-bold);
}
.nav-link:hover {
  background-color: transparent !important;
}

.nav-link:focus {
  border: none !important;
  background-color: transparent !important;
  color: var(--clr-accent);
}

.navbar-item:active {
  border: none !important;
}

.nav-link:active {
  color: var(--clr-accent);
}

.nav-link:active {
  border: none !important;
  background-color: transparent !important;
  color: var(--clr-accent);
}

.nav__link {
  margin-bottom: 10px;
  font-weight: var(--fw-bold) !important;
  font-size: 16px;
  padding-top: 3px;
  padding-bottom: 3px;
}

.contact-us {
  margin-top: 20px;
}

.nav-item:last-child {
  margin-right: 100px;
}

.nav-link:hover {
  color: var(--clr-accent) !important;
}

.navbar-item img {
  max-height: 5rem;
}

.hero-title {
  font-family: var(--ff-secondary) !important;
  font-weight: bolder;
}

.affirm-title {
  font-family: var(--ff-secondary) !important;
  font-weight: bold;
}

.hero-text {
  margin-left: 80px;
}

@media (min-width: 320px) and (max-width: 480px) {
  .navbar-item img {
    max-height: 3rem;
  }

  .navbar-burger {
    margin-top: 5px;
    margin-right: 10px;
  }

  .nav-btn {
    display: inline-block;
    padding: 0;
    background: none;
    color: var(--clr-accent);
    text-decoration: none;
    cursor: pointer;
    font-size: inherit;
    /* text-transform: uppercase; */
    letter-spacing: 1px;
    border-radius: none;
    font-weight: bolder;
    /* transition: transform 200ms ease-in-out; */
  }

  .nav-btn:hover {
    background-color: none;
    border: none;
    color: var(--clr-accent);
  }

  .hero-text {
    margin-left: 0;
  }
  .hero-title {
    font-size: 30px;
  }

  .affirm-title {
    font-size: 20px;
  }

  .contact-us {
    width: 50px;
  }

  .hero-btn {
    margin-left: 55px;
  }
  .section__title--intro1 {
    margin-bottom: 1em;
    margin-top: 1.5em !important;
  }
  .hero-para {
    font-size: var(--fs-h4);
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .hero-img {
    margin-top: 70px;
  }
  .nav-link {
    margin-right: 25px;
  }

  .hero-text {
    margin-left: 0;
  }
}

@media (min-width: 768px) and (max-width: 1000px) {
  .nav-btn {
    display: inline-block;
    padding: 0;
    background: none;
    color: var(--clr-accent);
    text-decoration: none;
    cursor: pointer;
    font-size: 0.6rem;
    /* text-transform: uppercase; */
    letter-spacing: 2px;
    border-radius: none;
    font-weight: bolder;
    /* transition: transform 200ms ease-in-out; */
  }

  .nav-btn:hover {
    background-color: none;
    border: none;
    color: var(--clr-accent);
  }
}
