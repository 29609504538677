@import '../../App.css';

.notfound-container {
  background-image: url('/images/darky.jpg');
  background-size: contain;
  /* background-repeat: no-repeat; */
  min-height: 1000px;
}

.nf-content {
  width: 50%;
  margin: 200px auto;
}

.nf-h3 {
  color: var(--clr-accent);
  margin-bottom: 15px;
}

.nf-h2 {
  color: var(--clr-light);
  margin-bottom: 15px;
}
.nf-para {
  color: var(--clr-accent);
  margin-bottom: 15px;
}

.btn {
  display: inline-block;
  padding: 0.5em 2.5em;
  background: var(--clr-dark);
  color: var(--clr-accent);
  border: 2px solid var(--clr-accent);
  text-decoration: none;
  cursor: pointer;
  text-align: center;
  font-size: 0.8rem;
  width: 210px;
  /* text-transform: uppercase; */
  letter-spacing: 2px;
  border-radius: 20px;
  font-weight: var(--fw-bold);
  /* transition: transform 200ms ease-in-out; */
}

.btn:hover {
  background-color: var(--clr-accent);
  border: 2px solid var(--clr-dark);
  color: var(--clr-light);
}

@media (min-width: 332px) and (max-width: 480px) {
  .notfound-container {
    background-size: contain;
    background-repeat: no-repeat;
    min-height: 650px;
  }

  .nf-para {
    font-size: 15px;
  }

  .nf-content {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 80px;
  }
}

@media (min-width: 300px) and (max-width: 330px) {
  .notfound-container {
    background-size: contain;
    background-repeat: no-repeat;
    min-height: 480px;
  }

  .nf-para {
    font-size: 15px;
  }

  .nf-content {
    width: 100%;
    margin: 50px auto;
  }
}
