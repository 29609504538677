@import '../../App.css';

.react-tabs__tab {
  border: none !important;
  margin-right: 20px;
  font-weight: bolder;
  padding-bottom: 20px !important;
}

.react-tabs__tab:hover {
  color: var(--clr-accent);
  border-bottom: var(--clr-accent) !important;
}

.react-tabs__tab:active {
  color: var(--clr-accent);
  border-bottom: var(--clr-accent) !important;
}

ul.react-tabs__tab-list {
  border-bottom: 1px solid rgb(190, 187, 187) !important;
  text-align: center !important;
}

.content {
  text-align: center;
  margin-top: 50px;
}

.main-content {
  display: inline-block;
  margin-right: 80px;
}

.content-heading {
  margin-top: 10px;
  font-weight: 200 !important;
  font-size: 15px !important;
}

@media (min-width: 320px) and (max-width: 480px) {
  .content {
    text-align: center;
    margin-top: 30px;
  }

  .main-content {
    display: inline-block;
    margin-right: 30px;
  }
}
