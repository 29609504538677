@import '../../App.css';

section {
  padding-bottom: 0;
  padding-top: 2em;
}
.services-h3 {
  font-weight: bold;
  font-size: 1.5rem;
}

.services-title {
  font-family: var(--ff-secondary) !important;
  font-weight: bolder;
}

ul {
  margin-top: 10px;
}

li {
  margin-left: 20px;
  list-style-type: square;
}

.project-title {
  color: var(--clr-accent);
  font-weight: bold;
  font-size: 30px;
}
.services-section {
  padding-bottom: 30px;
}
@media (min-width: 320px) and (max-width: 480px) {
  .services-title {
    font-size: 2rem;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
}

@media (min-width: 320px) and (max-width: 1024px) {
  .reverse-columns {
    flex-direction: column-reverse;
    display: flex;
  }
}
