@import '../../App.css';

.port-section {
  margin-top: 40px;
}

.port-heading {
  margin-top: 20px;
  font-size: 50px;
  font-weight: 900;
}

ul.portfolio-list {
  width: 100%;
  list-style: none;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 5px;
  margin-bottom: 30px;
}

ul.portfolio-list li {
  display: flex;
  flex-direction: column;
  margin-top: 25px;
}

ul.portfolio-list li img {
  width: 300px;
  height: 100%;
  opacity: 1;
  transition: transform 200ms ease-in-out;
  width: 100%;
}

ul.portfolio-list li img:hover {
  transform: scale(1.08);
}

ul.portfolio-list li .port-title {
  margin-top: 12px;
  font-size: 24px;
}

.project-technology {
  margin: 10px 0;
}

.port-title {
  font-size: 20px;
  font-weight: 600;
  color: var(--clr-dark);
}

.port-subheading {
  font-size: 40px;
  font-weight: 900;
  text-align: center;
}

.project-desc {
  font-size: 16px;
  line-height: 26px;
  margin-top: 5px;
}

.project-category {
  color: #81959e;
  font-size: 15px;
  text-transform: uppercase;
  font-size: 400;
  margin-top: 10px;
  min-height: 26px;
}

ul.portfolio-list li span {
  display: inline-block;
  border: 1px solid #dae1e4;
  border-radius: 12px;
  font-size: 12px;
  color: #81959e;
  font-weight: 400;
  padding: 1px 10px;
  margin-right: 4px;
}

.preview-btn {
  /* display: flex; */
  padding: 0.5em 2.5em;
  background: var(--clr-accent);
  color: var(--clr-light);
  text-decoration: none;
  cursor: pointer;
  font-size: 0.8rem;
  /* text-transform: uppercase; */
  letter-spacing: 2px;
  border-radius: 50px;
  font-weight: var(--fw-bold);
  margin-left: 40%;

  /* transition: transform 200ms ease-in-out; */
}

.preview-btn:hover {
  background-color: var(--clr-light);
  border: 2px solid var(--clr-accent);
  color: var(--clr-accent);
}

@media (min-width: 768px) and (max-width: 1020px) {
  ul.portfolio-list {
    grid-template-columns: 1fr 1fr;
  }
  .port-heading {
    text-align: center;
  }

  .preview-btn {
    margin-left: 40%;
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  .port-section {
    margin-top: 15px;
  }
  ul.portfolio-list {
    grid-template-columns: 1fr;
  }

  .port-heading {
    text-align: center;
  }

  .port-subheading {
    font-size: 30px;
    font-weight: 700;
    text-align: left;
    margin-left: 25px;
  }

  .preview-btn {
    margin-left: 25%;
  }
}
