.post-content {
  width: 100%;
}

.post-header {
  margin-bottom: 20px;
}

.post-header a {
  color: black;
}
.post-btn {
  margin-left: 80%;
}
.loader {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  height: 100px;
  width: 100px;
}

.loader-div {
  height: 1000px;
  margin: auto;
}

@media (min-width: 320px) and (max-width: 480px) {
  .post-btn {
    margin-left: 45%;
    padding: 0.5em 1.5em !important;
    font-size: 0.5rem !important;
  }
}
